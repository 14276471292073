/* imports */
import './../style/about.css';
import { useTranslation } from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {useScrollPosition} from "../hooks/useScrollPosition";

/* img */
import grayPeople from './../img/about/gray-people.jpg';
import metalGlobe from './../img/about/metal-globe.jpg';

/* team photos */
import placeholderWoman from './../img/about/members/Placeholders/young-business-woman-icon.webp';
import placeholderMan from './../img/about/members/Placeholders/young-businessman-icon.webp';

function About() {

    const [scrolled, setScrolled] = useState(0.0);
    const grayPeopleImgRef = useRef(document.getElementById("grayPeopleImg"));
    const metalGlobeImgRef = useRef(document.getElementById("metalGlobeImg"));


    // internationalization
    const {t} = useTranslation();


    // scroll driven animations
    const maxScrollHeight = document.documentElement.scrollHeight - 300;

    useScrollPosition(
        ({previousPosition, currentPosition}) => {

            setScrolled((currentPosition.y / maxScrollHeight) * -1);

            // uncomment for debug
            // console.log('current: (' + currentPosition.x + ', ' + currentPosition.y + ')');
            // console.log('previous: (' + previousPosition.x + ', ' + previousPosition.y + ')');
            // console.log(scrolled);
        },
        [scrolled],
        false,
        false,
        0
    );

    // scroll driven animations css for two sides section
    useEffect(() => {
        if (window.window > 1300) {
            const element = grayPeopleImgRef.current;
            element.style.transform = `translateX(calc(-20vw * ${scrolled}))`
        }
    }, [scrolled]);

    useEffect(() => {
        const element = metalGlobeImgRef.current;
        element.style.transform = `translateX(calc(-20vw * ${scrolled}))`;
    }, [scrolled]);

    // scroll-driven/intersection animations
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('about-show');
            }
            else {
                entry.target.classList.remove('about-show');
            }
        })
    });

    const hiddenElements = document.querySelectorAll('.about-hidden');
    hiddenElements.forEach((element) => observer.observe(element));

    // scroll to top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="about">

            {/* spacing */}
            <div className="about-top-spacing"/>

            {/* two sides section */}
            <div className="about-two-sides-section">

                {/* img */}
                <div className="about-two-sides-section-img-container">
                    <img id="grayPeopleImg" ref={grayPeopleImgRef} src={grayPeople} alt="grayPeople"/>
                </div>

                {/* text block */}
                <div className="about-two-sides-section-text-block-section">

                    {/* title */}
                    <div className="about-two-sides-section-text-block-title about-hidden">
                        {t("about.two-sides-section.title")}
                    </div>

                    {/* text */}
                    <div className="about-two-sides-section-text-block-text-container">
                        <div className="about-two-sides-section-text-block-text about-hidden">
                            <p>{t("about.two-sides-section.text-1")}</p>
                            <br/>
                            <br/>
                            <p>{t("about.two-sides-section.text-2")}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* img background section */}
            <div className="about-img-background-section">

                {/* img */}
                <div className="about-img-background-img-container">
                    <img id="metalGlobeImg" ref={metalGlobeImgRef} src={metalGlobe} alt="metalGlobe"/>
                    <div className="about-img-background-overlay"/>
                </div>


                {/* content */}
                <div className="about-img-background-content-container">

                    {/* left side */}
                    <div className="about-img-background-left-block about-hidden">

                        {/* block title */}
                        <div className="about-img-background-block-title">
                            {t("about.img-background-section.left-block.title")}
                        </div>

                        {/* block text */}
                        <div className="about-img-background-left-block-text">
                            {t("about.img-background-section.left-block.text")}
                        </div>

                    </div>

                    {/* right side */}
                    <div className="about-img-background-right-block about-hidden">

                        {/* block title */}
                        <div className="about-img-background-block-title">
                            {t("about.img-background-section.right-block.title")}
                        </div>

                        {/* inner blocks section */}
                        <div className="about-img-background-right-inner-blocks-section">

                            {/* block 1 */}
                            <div className="about-img-background-right-inner-block-container">
                                <div className="about-img-background-right-inner-block-title">
                                    {t("about.img-background-section.right-block.block-1.title")}
                                </div>
                                <div className="about-img-background-right-inner-block-text">
                                    {t("about.img-background-section.right-block.block-1.text")}
                                </div>
                            </div>

                            {/* block 2 */}
                            <div className="about-img-background-right-inner-block-container">
                                <div className="about-img-background-right-inner-block-title">
                                    {t("about.img-background-section.right-block.block-2.title")}
                                </div>
                                <div className="about-img-background-right-inner-block-text">
                                    {t("about.img-background-section.right-block.block-2.text")}
                                </div>
                            </div>

                            {/* block 3 */}
                            <div className="about-img-background-right-inner-block-container">
                                <div className="about-img-background-right-inner-block-title">
                                    {t("about.img-background-section.right-block.block-3.title")}
                                </div>
                                <div className="about-img-background-right-inner-block-text">
                                    {t("about.img-background-section.right-block.block-3.text")}
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

            {/* our team presentation */}
            <div className="about-team-section">

                {/* title */}
                <div className="about-team-title-section">
                    <div className="about-team-title">
                        {t("about.team.title")}
                    </div>
                    <div className="about-team-company-name">
                        Humane Business Progress
                    </div>
                </div>

                {/* team members container */}
                <div className="about-team-members-container">

                    {/* team line 1 */}
                    <div className="about-team-members-line about-hidden">

                        {/* Edyta Sasor-Adamczyk */}
                        <div className="about-team-member-block about-hidden">
                            <div className="about-team-member-img-container">
                                <img src={placeholderWoman} alt={t("about.team.members.member-1.name")}/>
                            </div>
                            <div className="about-team-member-description">
                                <div className="about-team-member-name">
                                    {t("about.team.members.member-1.name")}
                                </div>
                                <div className="about-team-member-description-spacing"/>
                                <div className="about-team-member-position">
                                    {t("about.team.members.member-1.position")}
                                </div>
                            </div>
                        </div>

                        {/* Michał Hadryś */}
                        <div className="about-team-member-block about-hidden">
                            <div className="about-team-member-img-container">
                                <img src={placeholderMan} alt={t("about.team.members.member-2.name")}/>
                            </div>
                            <div className="about-team-member-description">
                                <div className="about-team-member-name">
                                    {t("about.team.members.member-2.name")}
                                </div>
                                <div className="about-team-member-description-spacing"/>
                                <div className="about-team-member-position">
                                    {t("about.team.members.member-2.position")}
                                </div>
                            </div>
                        </div>

                        {/* Michał Kozłowski */}
                        <div className="about-team-member-block about-hidden">
                            <div className="about-team-member-img-container">
                                <img src={placeholderMan} alt={t("about.team.members.member-3.name")}/>
                            </div>
                            <div className="about-team-member-description">
                                <div className="about-team-member-name">
                                    {t("about.team.members.member-3.name")}
                                </div>
                                <div className="about-team-member-description-spacing"/>
                                <div className="about-team-member-position">
                                    {t("about.team.members.member-3.position")}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* team line 2 */}
                    <div className="about-team-members-line about-hidden">

                        {/* Edyta Gecewicz */}
                        <div className="about-team-member-block about-hidden">
                            <div className="about-team-member-img-container">
                                <img src={placeholderWoman} alt={t("about.team.members.member-4.name")}/>
                            </div>
                            <div className="about-team-member-description">
                                <div className="about-team-member-name">
                                    {t("about.team.members.member-4.name")}
                                </div>
                                <div className="about-team-member-description-spacing"/>
                                <div className="about-team-member-position">
                                    {t("about.team.members.member-4.position")}
                                </div>
                            </div>
                        </div>

                        {/* Mateusz Sasor-Adamczyk */}
                        <div className="about-team-member-block about-hidden">
                            <div className="about-team-member-img-container">
                                <img src={placeholderMan} alt={t("about.team.members.member-5.name")}/>
                            </div>
                            <div className="about-team-member-description">
                                <div className="about-team-member-name">
                                    {t("about.team.members.member-5.name")}
                                </div>
                                <div className="about-team-member-description-spacing"/>
                                <div className="about-team-member-position">
                                    {t("about.team.members.member-5.position")}
                                </div>
                            </div>
                        </div>

                        {/* Kamila Męcik */}
                        <div className="about-team-member-block about-hidden">
                            <div className="about-team-member-img-container">
                                <img src={placeholderWoman} alt={t("about.team.members.member-6.name")}/>
                            </div>
                            <div className="about-team-member-description">
                                <div className="about-team-member-name">
                                    {t("about.team.members.member-6.name")}
                                </div>
                                <div className="about-team-member-description-spacing"/>
                                <div className="about-team-member-position">
                                    {t("about.team.members.member-6.position")}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>


            {/* bottom spacing */}
            <div className="about-bot-spacing"/>

        </div>
    )
}

export default About;