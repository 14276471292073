/* imports */
import './../style/contact.css';
import { useTranslation } from "react-i18next";
import React, {useEffect, useState} from "react";

import phoneGuy from './../img/contact/phone-guy.jpg'

function Contact() {

    // internationalization
    const {t} = useTranslation();

    // box menu
    const [menuItem, setMenuItem] = useState(1)

    // scroll to top
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (

        <div className="contact">
            <div className="contact-inner-container">

                {/* navigation background */}
                <div className="contact-navigation-background"/>

                {/* page background */}
                <div className="contact-background">
                    <img src={phoneGuy} alt="phone guy img"/>
                    <div className="contact-background-overlay"/>
                </div>

                {/* page background overlay icons */}
                <div className="contact-background-overlay-icon"/>

                {/* page title */}
                <div className="contact-page-title">
                    {t("contact.page-title")}
                </div>

                {/* two side section */}
                <div className="contact-two-side-section-container">
                    <div className="contact-two-side-section-box-container">
                        <div className="contact-two-side-section-box">

                            <div className="contact-two-side-section-box-menu">

                                <div className={menuItem === 1
                                    ? "contact-two-side-section-box-menu-item contact-two-side-section-box-menu-item-active"
                                    : "contact-two-side-section-box-menu-item contact-two-side-section-box-menu-item-inactive"}
                                onClick={() => setMenuItem(1)}
                                >
                                    <div className="contact-two-side-section-box-menu-item-text">
                                        {t("contact.menu.item-1.title")}
                                    </div>
                                </div>

                                <div className={menuItem === 2
                                ? "contact-two-side-section-box-menu-item contact-two-side-section-box-menu-item-active"
                                : "contact-two-side-section-box-menu-item contact-two-side-section-box-menu-item-inactive"}
                                onClick={() => setMenuItem(2)}
                                >
                                    <div className="contact-two-side-section-box-menu-item-text">
                                        {t("contact.menu.item-2.title")}
                                    </div>
                                </div>

                            </div>

                            <div className="contact-two-side-section-box-text">

                                <div className="contact-two-side-section-box-text-line">
                                    {
                                        menuItem === 1
                                        ? t("contact.menu.item-1.text.line-1")
                                        : t("contact.menu.item-2.text.line-1")
                                    }
                                </div>

                                <div className="contact-two-side-section-box-text-line">
                                    {
                                        menuItem === 1
                                            ? t("contact.menu.item-1.text.line-2")
                                            : t("contact.menu.item-2.text.line-2")
                                    }
                                </div>

                                <div className="contact-two-side-section-box-text-line">
                                    {
                                        menuItem === 1
                                            ? t("contact.menu.item-1.text.line-3")
                                            : t("contact.menu.item-2.text.line-3")
                                    }
                                </div>

                                <div className="contact-two-side-section-box-text-spacing"/>

                                <div className="contact-two-side-section-box-text-line">
                                    {
                                        menuItem === 1
                                            ? t("contact.menu.item-1.text.line-4")
                                            : t("contact.menu.item-2.text.line-4")
                                    }
                                </div>

                                <div className="contact-two-side-section-box-text-line">
                                    {
                                        menuItem === 1
                                            ? t("contact.menu.item-1.text.line-5")
                                            : t("contact.menu.item-2.text.line-5")
                                    }
                                </div>

                                <div className="contact-two-side-section-box-text-line">
                                    {
                                        menuItem === 1
                                            ? t("contact.menu.item-1.text.line-6")
                                            : t("contact.menu.item-2.text.line-6")
                                    }
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="contact-two-side-section-empty"/>
                </div>

            </div>
        </div>
    )
}

export default Contact