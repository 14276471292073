// Imports section ------------------------------------------------------------

// React
import {Suspense, useEffect} from "react";

// Pages and components
import Header from "./components/fragments/Header";
import Footer from "./components/fragments/Footer"
import Homepage from "./components/Homepage";
import About from "./components/About";
import Offer from "./components/Offer";
import Contact from "./components/Contact";


// internationalization
import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import en from "./locales/EN-us.json";
import pl from "./locales/PL-pl.json";
import fr from "./locales/FR-fr.json";

// Routing
import { Routes, Route } from "react-router-dom";

// end of section -------------------------------------------------------------

// internationalization
const translationsPL = pl
const translationsEN = en
const translationsFR = fr

i18n
    .use(initReactI18next)
    .init({
        resources: {
            pl: { translation: translationsPL },
            en: { translation: translationsEN },
            fr: { translation: translationsFR }
        },
        lng: "pl",
        interpolation: { escapeValue: false }
    });

// Main app
function App() {
    return (
        <Suspense fallback="Loading...">
            <Header />

            <Routes>
                <Route path="/" element={ <Homepage /> } />

                <Route path="about">
                    <Route index={ true } element={<About />} />
                </Route>

                <Route path="offer">
                    <Route index={ true } element={<Offer />} />
                </Route>

                <Route path="contact">
                    <Route index={ true } element={<Contact />} />
                </Route>
            </Routes>

            <Footer />
        </Suspense>
    );
}

export default App;
