import './../../style/footer.css';
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";
import verticalLogo from './../../img/footer/logo-vertical.webp';

function Footer() {

    // internationalization
    const {t} = useTranslation();

    return (
        <footer>

            {/* information's within the footer */}
            <div className="footer-container">
                <div className="footer-block">
                    <div className="footer-logo-container">
                        <img src={verticalLogo} alt="logo"/>
                    </div>
                </div>

                <div className="footer-block">
                    <div className="footer-horizontal-line"/>

                    <div className="footer-block-title">
                        {t("footer.address.title")}
                    </div>

                    <div className="footer-block-text-row">
                        {t("footer.address.text-1")}
                    </div>

                    <div className="footer-block-text-row">
                        {t("footer.address.text-2")}
                    </div>
                </div>

                <div className="footer-block">
                    <div className="footer-horizontal-line"/>

                    <div className="footer-block-title">
                        {t("footer.contact.title")}
                    </div>

                    <div className="footer-block-text-row">
                        {t("footer.contact.telephone")}
                    </div>

                    <div className="footer-block-text-row">
                        {t("footer.contact.e-mail")}
                    </div>
                </div>

                <div className="footer-block">
                    <div className="footer-horizontal-line"/>

                    <div className="footer-block-title">
                        {t("footer.follow-us")}
                    </div>

                    <div className="footer-block-links-row">

                        <div id="Linkedin-link" className="footer-block-text-link">
                            <Link to="#">LinkedIn</Link>
                        </div>

                        <div className="footer-block-text-vertical-separator"/>

                        <div id="Twitter-link" className="footer-block-text-link">
                            <Link to="#">Twitter</Link>
                        </div>

                    </div>
                </div>

            </div>

        </footer>
    );
}

export default Footer;